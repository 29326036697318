"use client";

import React, { Suspense } from "react";
import "../style.scss";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardImage } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { IPaymentLink } from "@/composables/paymentlink.type";
// import { formatRp } from '@/lib/hybridUtils';
import { findCheapestTierPeriodPrice, formatRp, getCoverImage } from "@/utils";
import { productPageDetail } from "@/utils";

type ProductOptionType = {
  value: string;
  label: string;
  isDisabled: boolean;
  color: string;
  tailwindcolor: string;
};

const ProductCard = ({
  id,
  name,
  amount,
  coverImage,
  multipleImage,
  type,
  productOption,
  paymeLink,
  link,
  subType,
  membershipTier,
  crossoutPrice,
}: Partial<
  IPaymentLink & { productOption: ProductOptionType; paymeLink: string }
>) => {
  const router = useRouter();
  const { t } = useTranslation();
  const goToPayLink = (
    link: string,
    type: string,
    subType: string | undefined
  ) => {
    // router.prefetch = async () => { }

    let payLink = `/pl/${link}`;

    if (type === "physical_product") {
      payLink = `/checkout/${link}`;
    }

    if (type === "membership") {
      payLink = `/m/${link}`;
    }

    if (subType) {
      payLink = `/donate/${link}`;
    }

    return payLink;
    // return router.push(payLink);
  };
  let imageSrc = getCoverImage(coverImage, multipleImage, "sm");

  let cheapestMembershipTierPeriod = 0;
  if (type === "membership")
    cheapestMembershipTierPeriod =
      findCheapestTierPeriodPrice(membershipTier)?.lowestAmountPeriod;

  return (
    // <Suspense
    //   fallback={
    //     <Skeleton className='lg:w-[31%] sm:w-[47%]'/>
    //   }
    // >
    <Link
      href={productPageDetail(paymeLink, link, type, subType)}
      className="xl:w-[31.6%] lg:w-[31%] sm:w-[47%] w-full"
      prefetch={true}
    >
      <Card className="w-full bg-white relative rounded-sm">
        <Badge
          className={`absolute top-4 left-4 text-white text-[10px] font-open-sans font-[600]`}
          style={{
            backgroundColor: productOption?.color,
          }}
        >
          {productOption?.label}
        </Badge>
        <CardImage
          loading="eager"
          fetchPriority="high"
          src={imageSrc || "/digital-product-placeholder.webp"}
          alt=""
          className="h-[240px] !rounded-t-sm"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/digital-product-placeholder.webp";
            imageSrc = "/digital-product-placeholder.webp";
          }}
        />
        <CardContent className="p-[20px] flex flex-col font-open-sans">
          <div className="text-[16px] text-[#6c757d] mb-[10px] -mt-[2px] font-[600] truncate">
            {name}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              {crossoutPrice && crossoutPrice > amount! && (
                <p className="font-[500] text-[#343a40] text-[13px] m-0 leading-[24px]">
                  <s className="text-secondary">{`${formatRp(
                    crossoutPrice
                  )}`}</s>
                </p>
              )}
              <p className="font-[500] text-[#343a40] text-[16px] m-0 leading-[24px]">
                {formatRp(
                  type === "membership" ? cheapestMembershipTierPeriod : amount!
                )}
              </p>
            </div>
            <Link href={goToPayLink(link!, type!, subType)} prefetch={true}>
              <Button
                variant={"primary"}
                className="text-[11px] rounded px-[15px] py-[9px] z-50 uppercase flex justify-center items-center leading-[17px] h-auto"
                // onClick={(event) => {
                //   event.stopPropagation();
                //   event.preventDefault();
                //   goToPayLink(link!, type!, subType)
                // }}
              >
                {t("word.buy")}
              </Button>
            </Link>
          </div>
        </CardContent>
      </Card>
    </Link>
    // </Suspense>
  );
};

export default ProductCard;
