"use client";

import React, { FC } from "react";
import { Button } from "@/components/ui/button";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

type ButtonLoadMoreType = {
  handleFetch: () => void;
  isLoading: boolean;
};

const ButtonLoadMore: FC<ButtonLoadMoreType> = ({ handleFetch, isLoading }) => {
  const { t } = useTranslation();
  const handleRoute = () => {
    const params = new URLSearchParams(window.location.search);
    params.delete("pageSize");
    params.set("pageSize", "9");

    const url = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", url);

    window.location.reload();
  };
  return (
    <Button
      variant={"outline-primary"}
      className="text-[11px] font-[600] uppercase rounded"
      onClick={handleFetch}
      disabled={isLoading}
    >
      {isLoading ? (
        <Icon icon="svg-spinners:270-ring" className="text-lg" />
      ) : (
        t("paymentLink.showMore")
      )}
    </Button>
  );
};

export default ButtonLoadMore;
